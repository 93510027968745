import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  useTheme,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import GridButtonToolbar from "../../toolbars/GridButtonToolbar";
import { ruRU } from "@mui/x-data-grid";
import {
  getExpenses,
  createExpense,
  editExpense,
  deleteExpense,
} from "../../services/expenseService";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import UserContext from "../../contexts/userContext";

import { AuthContext } from "../../contexts/authContext";
import { titleConfig } from "../../data/config";

const categories = {
  WhatsApp: "WhatsApp",
  Voip: "Телефония",
  Databases: "Базы данных",
  Consumables: "Расходники",
};

const Expenses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { showAlert } = useContext(UserContext);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [currentExpense, setCurrentExpense] = useState({
    name: "",
    amount: "",
    description: "",
    category: "",
    created_date: null,
  });
  const [errors, setErrors] = useState({});

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [filterModel, setFilterModel] = useState();
  const [me, setMe] = useState(null);

  useEffect(() => {
    document.title = titleConfig.EXPENSES;
  }, []);

  useEffect(() => {
    if (user) {
      setMe(user);
    }
  }, [user]);

  const updateData = async () => {
    setLoading(true);
    try {
      const search = filterModel?.quickFilterValues?.join(" ") || "";
      const data = await getExpenses(page, pageSize, search);
      setExpenses(data.expenses);
      setTotalCount(data.totalCount);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateData();
  }, [page, pageSize, filterModel]);

  const handleCreate = () => {
    setCurrentExpense({
      name: "",
      amount: "",
      description: "",
      category: "",
      created_date: null,
    });
    setModalMode("create");
    setOpenModal(true);
  };

  const handleEdit = () => {
    if (selectedExpenses.length === 1) {
      const expenseToEdit = expenses.find(
        (expense) => expense.id === selectedExpenses[0]
      );
      setCurrentExpense({
        ...expenseToEdit,
        created_date: expenseToEdit.created_date
          ? moment(expenseToEdit.created_date)
          : null,
      });
      setModalMode("edit");
      setOpenModal(true);
    }
  };

  const handleDelete = async () => {
    if (selectedExpenses.length > 0) {
      try {
        await Promise.all(selectedExpenses.map((id) => deleteExpense(id)));
        await updateData();
        setSelectedExpenses([]);
        showAlert("Расходы успешно удалены", "success");
      } catch (error) {
        showAlert("Ошибка при удалении расходов: " + error.message, "error");
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!currentExpense.name) {
      newErrors.name = "Название расхода обязательно.";
    } else if (currentExpense.name.length > 25) {
      newErrors.name = "Название не может быть длиннее 25 символов.";
    }

    if (!currentExpense.amount || currentExpense.amount < 1) {
      newErrors.amount = "Сумма расхода должна быть больше или равна 1.";
    }

    if (!currentExpense.created_date) {
      newErrors.created_date = "Дата расхода обязательна.";
    }

    if (currentExpense.description && currentExpense.description.length > 255) {
      newErrors.description = "Описание не может быть длиннее 255 символов.";
    }

    if (!currentExpense.category) {
      newErrors.category = "Категория расхода обязательна.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        if (modalMode === "create") {
          const newExpense = await createExpense(currentExpense);
          if (newExpense) {
            await updateData();
            showAlert("Расход успешно добавлен", "success");
          }
        } else if (modalMode === "edit") {
          await editExpense(currentExpense, currentExpense.id);
          await updateData();
          showAlert("Расход успешно обновлен", "success");
        }
        setSelectedExpenses([]);
        setOpenModal(false);
      } catch (error) {
        showAlert("Ошибка при сохранении расхода: " + error.message, "error");
      }
    } else {
      showAlert(
        "Пожалуйста, заполните все обязательные поля корректно.",
        "error"
      );
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50, sortable: false },
    { field: "name", headerName: "Имя", width: 150, sortable: false },
    { field: "amount", headerName: "Сумма", width: 100, sortable: false },
    { field: "category", headerName: "Категория", width: 150, sortable: false },
    { field: "description", headerName: "Описание", width: 200, sortable: false },
    {
      field: "created_date",
      headerName: "Дата",
      width: 150,
      sortable: false,
      valueGetter: (params) =>
        params.value ? new Date(params.value).toLocaleDateString("ru-RU") : "",
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Расходы</Typography>
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
        }}
      >
        <DataGrid
          disableColumnSorting
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection
          selectionModel={selectedExpenses}
          rows={expenses}
          columns={columns}
          components={{
            Toolbar: () => (
              <GridButtonToolbar
                onDelete={handleDelete}
                onCreate={handleCreate}
                onEdit={handleEdit}
                selectedCount={selectedExpenses.length}
                no_delete={me?.role !== "admin"}
              />
            ),
          }}
          onSelectionModelChange={(newSelection) => setSelectedExpenses(newSelection)}
          loading={loading}
          pagination
          paginationMode="server"
          rowCount={totalCount}
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          filterMode="server"
          onFilterModelChange={(model) => setFilterModel(model)}
        />
      </Box>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          bgcolor={colors.primary[400]}
          borderRadius="8px"
          sx={{
            width: 400,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography color="white" variant="h6" gutterBottom>
            {modalMode === "create" ? "Создать расход" : "Редактировать расход"}
          </Typography>
          <TextField
            label="Название"
            value={currentExpense.name}
            onChange={(e) => setCurrentExpense({ ...currentExpense, name: e.target.value })}
            fullWidth
            margin="normal"
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
          <TextField
            label="Сумма"
            value={currentExpense.amount}
            onChange={(e) => setCurrentExpense({ ...currentExpense, amount: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
            error={Boolean(errors.amount)}
            helperText={errors.amount}
          />
          <TextField
            label="Описание"
            value={currentExpense.description}
            onChange={(e) => setCurrentExpense({ ...currentExpense, description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            error={Boolean(errors.description)}
            helperText={errors.description}
          />
          <Select
            value={currentExpense.category}
            onChange={(e) => setCurrentExpense({ ...currentExpense, category: e.target.value })}
            fullWidth
            margin="normal"
            displayEmpty
            error={Boolean(errors.category)}
          >
            <MenuItem value="" disabled>
              Выберите категорию
            </MenuItem>
            {Object.entries(categories).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {errors.category && (
            <Typography color="error" variant="body2">
              {errors.category}
            </Typography>
          )}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Дата"
              value={currentExpense.created_date}
              onChange={(newValue) => setCurrentExpense({ ...currentExpense, created_date: newValue })}
              sx={{ mb: 2, mt: 2 }}
              slotProps={{ textField: { variant: "outlined", fullWidth: true } }}
            />
          </LocalizationProvider>
          {errors.created_date && (
            <Typography color="error" variant="body2">
              {errors.created_date}
            </Typography>
          )}
          <Box mt="20px" display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {modalMode === "create" ? "Создать" : "Сохранить"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Expenses;
